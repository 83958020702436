<template>
    <div>
        <span
            class="text-link"
            @click="$emit('callback')">{{ item.vin }}</span>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>